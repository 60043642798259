<template>
  <div class="secondPoster">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>广告位列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="search">
      <span>位置名称:</span>
      <el-input
        v-model="list.placeName"
        placeholder="请输入位置名称"
      ></el-input>
      <span>位置编码:</span>
      <el-input
        v-model="list.placeCode"
        placeholder="请输入位置编码"
      ></el-input>
      <el-button type="primary" @click="getposterPlaceList()">查找</el-button>
      <el-button type="primary" @click="addDialogVisible = true"
        >添加</el-button
      >
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <!-- <el-table-column align="center" type="selection"></el-table-column> -->
      <el-table-column
        align="center"
        prop="placeId"
        label="广告位ID"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="placeName"
        label="广告位名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="placeCode"
        label="广告位编码"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="fatherName"
        label="上级名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="addTime"
        label="添加时间"
      ></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="{ row }">
          <el-button type="primary" size="mini" @click="onEdit(row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
        <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="list.currentPage"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="list.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    <!-- 添加广告位弹层 -->
    <el-dialog
      :close-on-click-modal="false"
      title="添加位置"
      :visible.sync="addDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="广告位名称">
          <el-input
            v-model="addFormList.placeName"
            placeholder="请输入广告位名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告位编码">
          <el-input
            v-model="addFormList.placeCode"
            placeholder="请输入广告位编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告位说明">
          <el-input
            type="textarea"
            rows="5"
            v-model="addFormList.placeRemark"
            placeholder="请输入广告位说明,选填项"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onAddOk">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑广告位弹层 -->
    <el-dialog
      :close-on-click-modal="false"
      title="添加位置"
      :visible.sync="editDialogVisible"
      width="30%"
    >
      <el-form ref="form" label-width="80px">
        <el-form-item label="广告位名称">
          <el-input
            v-model="editFormList.placeName"
            placeholder="请输入广告位名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告位编码">
          <el-input
            v-model="editFormList.placeCode"
            placeholder="请输入广告位编码"
          ></el-input>
        </el-form-item>
        <el-form-item label="广告位说明">
          <el-input
            type="textarea"
            rows="5"
            v-model="editFormList.placeRemark"
            placeholder="请输入广告位说明,选填项"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="onEditOk">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  posterPlaceList,
  addOrEditPosterPlace,
  posterPlaceDetail,
} from "../../../api/posterPlace";
export default {
  name: "secondPoster",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tableData: [],
      pagination: {},
      addDialogVisible: false,
      editDialogVisible: false,
      addFormList: {
        placeId: 0,
        fatherId: Number(this.id),
        placeName: "",
        placeCode: "",
        placeRemark: "",
      },
      editFormList: {
        placeId: null,
        fatherId: Number(this.id),
        placeName: "",
        placeCode: "",
        placeRemark: "",
      },
      list: {
        currentPage: 1,
        pageSize: 10,
        fatherId: Number(this.id),
        placeName: "",
        placeCode: "",
      },
    };
  },
  created() {
    this.getposterPlaceList();
  },
  methods: {
        handleSizeChange(num) {
      this.list.pageSize = num;
      this.getposterPlaceList();
    },
    handleCurrentChange(num) {
      this.list.currentPage = num;
      this.getposterPlaceList();
    },
    async getposterPlaceList() {
      const { data } = await posterPlaceList(this.list);
      this.tableData = data.list;
      this.pagination = data.pagination;
    },
    async onEditOk() {
      if (
        this.editFormList.placeName == "" ||
        this.editFormList.placeCode == ""
      ) {
        return this.$message.error("请把信息填写完整");
      }
      const { data } = await addOrEditPosterPlace(this.editFormList);
      if (data.code == 0) {
        this.getposterPlaceList();
        this.$message({
          message: data.msg,
          type: "success",
        });
      } else {
        this.$message.error(data.msg);
      }
      this.editDialogVisible = false;
    },
    async onEdit(row) {
      this.editFormList.placeId = row.placeId;
      const { data } = await posterPlaceDetail({
        placeId: row.placeId,
      });
      this.editFormList.placeName = data.data[0].placeName;
      this.editFormList.placeCode = data.data[0].placeCode;
      this.editFormList.placeName = data.data[0].placeName;
      this.editFormList.placeRemark = data.data[0].placeRemark;
      this.editDialogVisible = true;
    },
    async onAddOk() {
      if (
        this.addFormList.placeName == "" ||
        this.addFormList.placeCode == ""
      ) {
        return this.$message.error("请把信息填写完整");
      }
      const { data } = await addOrEditPosterPlace(this.addFormList);
      if (data.code == 0) {
        this.getposterPlaceList();
        this.$message({
          message: data.msg,
          type: "success",
        });
      } else {
        this.$message.error(data.msg);
      }
      this.addDialogVisible = false;
      this.addFormList.placeCode = "";
      this.addFormList.placeName = "";
      this.addFormList.placeRemark = "";
    },
  },
};
</script>
<style lang="less" scoped>
.secondPoster {
  .el-breadcrumb {
    height: 35px;
    border-bottom: 1px solid rgb(192, 191, 191);
  }
  .search {
    margin-top: 50px;
    span {
      margin: 0 20px 0 40px;
    }
    .el-button {
      margin-left: 20px;
    }
    .el-input {
      width: 350px;
    }
  }
  .el-table {
    margin-top: 50px;
  }
    .el-pagination {
    margin-top: 50px;
    float: right;
  }
}
</style>
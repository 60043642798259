import { render, staticRenderFns } from "./secondPoster.vue?vue&type=template&id=fa7ec752&scoped=true&"
import script from "./secondPoster.vue?vue&type=script&lang=js&"
export * from "./secondPoster.vue?vue&type=script&lang=js&"
import style0 from "./secondPoster.vue?vue&type=style&index=0&id=fa7ec752&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa7ec752",
  null
  
)

export default component.exports